import { Component, Input, OnInit } from '@angular/core';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { bannerModel } from '../model/drawer-item';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() breadCrumbItems: BreadCrumbItem[];
  @Input() wellHeader: bannerModel;
  constructor() { }

  ngOnInit(): void {
  }

  getSubHeader(wellHeader: bannerModel){
      let operatorName = (wellHeader.operatorName===undefined || wellHeader.operatorName===null)?'':`${wellHeader.operatorName} - `;
      let fieldName = (wellHeader.fieldName===undefined || wellHeader.fieldName===null)?'':`${wellHeader.fieldName}, `;
      let countyName = (wellHeader.countyName===undefined || wellHeader.countyName===null)?'':`${wellHeader.countyName} Co., `;
      let stateName = (wellHeader.stateName===undefined || wellHeader.stateName===null)?'':`${wellHeader.stateName}, `;
      let countryName = (wellHeader.countryName===undefined || wellHeader.countryName===null)?'':`${wellHeader.countryName}`;
      return `${operatorName}${fieldName}${countyName}${stateName}${countryName}`;
  }
}
