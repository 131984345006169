import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../_services/config.service';
import { Observable } from 'rxjs';
import { WellOverviewMenuModel, WellOverviewModel } from '../model/well-overview-menu.interface';
import { WellShortHeaderModel } from '../model/well-short-header.interface';
import {HttpBaseService} from '../service/http-base.service';

@Injectable({
    providedIn: 'root',
})
export class WellOverviewService extends HttpBaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService );
    }

    getWellOverviewMenu(pwiNumber: string):Observable<WellOverviewMenuModel[]> {
        var _timeStamp = (new Date()).toISOString().replace(/[^0-9]/g, '').slice(0, -3);
        return this.get<WellOverviewMenuModel[]>(`api/wellDashboard/datatypes/${_timeStamp}?pwiNumber=${pwiNumber}`);
    }

    getWellOverview(pwiNumber: string):Observable<WellOverviewModel> {
        return this.get<WellOverviewModel>(`api/wellDashboard/welloverview?pwiNumber=${pwiNumber}`);
}

    getWellShortHeader(pwiNumber: string):Observable<WellShortHeaderModel> {
        return this.get<WellShortHeaderModel>(`api/wellDashboard/wellshortheader?pwiNumber=${pwiNumber}`);
    }
}
