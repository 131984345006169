import { WellOverviewService } from '../service/well-overview.service';
import { Component, OnInit } from '@angular/core';
import { Helper } from '../../_helpers/helper';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { WellOverviewModel } from '../model/well-overview-menu.interface';

@Component({
  selector: 'app-well-overview',
  templateUrl: './well-overview.component.html',
  styleUrls: ['./well-overview.component.css']
})
export class WellOverviewComponent implements OnInit {
  position = {};
  pwi_nmbr_n = '';
  data_type_vc = '';
  wellLocation= '';
  overview: WellOverviewModel = null;
  private subscriptions: Subscription[] = [];
  private destroying$ = new Subject();

  constructor(private wellOverviewService: WellOverviewService, private loc: Location)
  {
    const path = this.loc.path();
    this.pwi_nmbr_n = Helper.getQueryString('pwi_nmbr_n', path);
    this.data_type_vc = Helper.getQueryString('data_type_vc', path);
  }

  ngOnInit(): void {
    this.wellOverviewService.getWellOverview(this.pwi_nmbr_n)
      .pipe(takeUntil(this.destroying$))
      .subscribe((overviewData: WellOverviewModel)=>{
          this.overview = overviewData;
          this.position = {
            lat: this.overview.wellLatitude,
            lng: this.overview.wellLongitude,
          }
          this.wellLocation = this.getWellInfo(this.overview);
      });
  }

  getWellInfo(overviewData: WellOverviewModel){
    let fieldName = (overviewData.fieldName===undefined || overviewData.fieldName===null)?'':`${overviewData.fieldName}, `;
    let countyName = (overviewData.countyName===undefined || overviewData.countyName===null)?'':`${overviewData.countyName} Co., `;
    let stateName = (overviewData.stateName===undefined || overviewData.stateName===null)?'':`${overviewData.stateName}, `;
    let countryName = (overviewData.countryName===undefined || overviewData.countryName===null)?'':`${overviewData.countryName}`;
    return `${fieldName}${countyName}${stateName}${countryName}`;
    }
}
