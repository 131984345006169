import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Material Form Controls
import { GridModule, PDFModule, ExcelModule} from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule  } from '@progress/kendo-angular-pdf-export';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';

import { LayoutModule } from '@progress/kendo-angular-layout';
import { UtilsModule } from '@progress/kendo-angular-utils';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { BreadCrumbModule } from '@progress/kendo-angular-navigation';
import { DialogModule } from '@progress/kendo-angular-dialog';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        GridModule,
        ButtonsModule,
        DropDownsModule,
        TreeViewModule,
        DateInputsModule,
        InputsModule,
        IntlModule,
        LabelModule,
        ExcelExportModule,
        PDFExportModule,
        PopupModule,
        IconsModule,
        ScrollViewModule,
        ChartsModule,
        LayoutModule,
        UtilsModule,
        PDFModule,
        ExcelModule,
        ListViewModule,
        BreadCrumbModule
    ],
    exports: [
        GridModule,
        ButtonsModule,
        DropDownsModule,
        TreeViewModule,
        DateInputsModule,
        InputsModule,
        IntlModule,
        LabelModule,
        ExcelExportModule,
        PDFExportModule,
        PopupModule,
        IconsModule,
        ScrollViewModule,
        ChartsModule,
        LayoutModule,
        UtilsModule,
        PDFModule,
        ExcelModule,
        ListViewModule,
        BreadCrumbModule,
        DialogModule
    ]
})

export class KendoUIModule { }
