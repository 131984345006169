<div class="parent">
    <div class="child1">
        <div class="subchild">
            <div style="background: #FFFFFF 0% 0% no-repeat padding-box;
            border-bottom: 1px solid #E5E5E5;
            opacity: 1;">
                <div style="margin-left: 10px;">Well Information</div>
            </div>
            <div style="margin-left: 10px;">
                <div *ngIf="overview.wellName">
                    {{overview.wellName}}
                </div>
                <div>
                    {{wellLocation}}
                </div>
                <div *ngIf="overview.uwiNumber">
                    {{overview.uwiNumber}}
                </div>
                <div *ngIf="overview.operatorName">
                    {{overview.operatorName}}
                </div>
                <br>
                <app-googlemaps [position] = "position"></app-googlemaps>
                <br>
                <div>
                    Location:
                </div>
                <div *ngIf="overview.wellLatitude">
                    Latitude: {{overview.wellLatitude}}
                </div>
                <div *ngIf="overview.wellLongitude"> 
                    Longitude: {{overview.wellLongitude}}
                </div>
                <div *ngIf="overview.wellDatum">
                    Datum: {{overview.wellDatum}}
                </div>
                <div *ngIf="overview.wellGroundlevelElvation">
                    Ground Elevation: {{overview.wellGroundlevelElvation}} {{overview.wellUnit}}
                </div>
                <div *ngIf="overview.wellKellyBushingElevation">
                    KB Elevation/Rig Height: {{overview.wellKellyBushingElevation}} {{overview.wellUnit}}
                </div>
                <div *ngIf="overview.wellGroundlevelElvation">
                    Ground Elevation: {{overview.wellGroundlevelElvation}} {{overview.wellUnit}}
                </div>
                <div *ngIf="overview.wellDepth">
                    Total Depth: {{overview.wellDepth}} {{overview.wellUnit}}
                </div>
                <br>
                <!-- <div>
                    Mud Systems:
                </div>
                <div>
                    Total Depth: {{overview.wellDepth}} {{overview.wellUnit}}
                </div> -->
                <br>
                <div>
                    Dates:
                </div>
                <div *ngIf="overview.wellSpudDate">
                    Spud: {{overview.wellSpudDate}}
                </div>
                <div *ngIf="overview.wellTotalDepthDate">
                    Total Depth: {{overview.wellTotalDepthDate}}
                </div>
                <br>
                <div>
                    Basin:
                </div>
                <div *ngIf="overview.wellBasinName">
                    {{overview.wellBasinName}}
                </div>
            </div>
        </div>
    </div>
    <div class="child2">
    </div>
    <div class="child3">
    </div>
</div>
