import { WellOverviewService } from '../service/well-overview.service';
import { Component, OnInit} from '@angular/core';
import { Helper } from '../../_helpers/helper';
import { Location } from '@angular/common';
import { groupBy, mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { Subject, Subscription, from } from 'rxjs';
import { WellOverviewMenuModel, WellOverviewMenuUrlModel } from '../model/well-overview-menu.interface';
import {DrawerItem} from "@progress/kendo-angular-layout";
import { WellShortHeaderModel } from '../model/well-short-header.interface';
import { environment } from '../../../../src/environments/environment';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

@Component({
  selector: 'app-rapid-well',
  templateUrl: './rapid-well.component.html',
  styleUrls: ['./rapid-well.component.css']
})
export class RapidWellComponent implements OnInit {
  pwi_nmbr_n = '';
  data_type_vc = '';
  vrsUrl: string = environment.vrsUrl;
  isProduction: boolean;
  isOverview: boolean = true;
  overviewUrl: string = '';
  welloverview: WellOverviewMenuModel[] = [];
  wellheader: WellShortHeaderModel
  wellOverviewArrayWithUrl: WellOverviewMenuUrlModel[] = [];
  public items: Array<DrawerItem> = [];
  breadCrumbItems: BreadCrumbItem[];

  public OverViewitems: Array<DrawerItem> = [
    {
      text: "Overview",
      selected: true,
      id: 0,
    }
  ];
  private subscriptions: Subscription[] = [];
  private destroying$ = new Subject();

  constructor(private wellOverviewService: WellOverviewService, private loc: Location)
  {
    const path = this.loc.path();
    this.pwi_nmbr_n = Helper.getQueryString('pwi_nmbr_n', path);
    this.data_type_vc = Helper.getQueryString('data_type_vc', path);
  }

  ngOnInit(): void {
    let idcount = 0;
    this.overviewUrl  = `${this.vrsUrl}res_wll.infoView?pwi_nmbr_n=${this.pwi_nmbr_n}&sctn_grp_vc=SMRY`;
    //set url for overview option
    let wellOverviewWithUrl: WellOverviewMenuUrlModel = {
      url : this.overviewUrl,
      id : 0
    }
    this.isProduction = environment.production;
    this.isOverview = !this.isProduction;
    this.wellOverviewArrayWithUrl.push(wellOverviewWithUrl);
    //get welloverview menu
    this.wellOverviewService.getWellOverviewMenu(this.pwi_nmbr_n)
    .pipe(takeUntil(this.destroying$))
            .subscribe((overviewData: WellOverviewMenuModel[]) => {
                this.welloverview = overviewData;
                //create group by group order
                const overview = from(this.welloverview).pipe(groupBy(menu => menu.grpOrder), mergeMap(group => group.pipe(toArray())))
                .subscribe(p => {
                  //set id
                  idcount = idcount +1;
                  let text = p[0].grpLabel;
                  var trademark = "\u2122";
                  if(p[0].grpLabel.includes('&') && p[0].grpLabel.includes(';')){
                    text = p[0].grpLabel.replace('&trade;', trademark);
                  }
                  if(p[0].grpCode != p[0].dataTypeCode){
                   //if p.length is greater than 1 create a child
                    //create a parent
                  let item: DrawerItem ={
                    text: text,
                    id : idcount,
                  }
                  this.OverViewitems.push(item);
                  let parentId = idcount;
                  //create a child
                  p.forEach(x=>{
                    idcount = idcount +1;
                    let item: DrawerItem ={
                      text: x.dataTypeLabel,
                      id: idcount,
                      parentId: parentId
                    };
                    this.OverViewitems.push(item);
                    //set wellOverviewArrayWithUrl
                    let wellOverviewWithUrl: WellOverviewMenuUrlModel = {
                      url : this.setUrl(x),
                      id : idcount
                    }
                    this.wellOverviewArrayWithUrl.push(wellOverviewWithUrl);
                  })
                  }
                  else{
                  //create a child
                  p.forEach(x=>{
                    let text = x.dataTypeLabel;
                    if(x.dataTypeLabel.includes('&') && x.dataTypeLabel.includes(';')){
                      text = x.dataTypeLabel.replace('&trade;', trademark);
                    }
                    idcount = idcount +1;
                    let item: DrawerItem ={
                      text: x.dataTypeLabel,
                      id: idcount
                    };
                    this.OverViewitems.push(item);
                    //set wellOverviewArrayWithUrl
                    let wellOverviewWithUrl: WellOverviewMenuUrlModel = {
                      url : this.setUrl(x),
                      id : idcount
                    }
                    this.wellOverviewArrayWithUrl.push(wellOverviewWithUrl);
                  })
                  }
                });
                this.items = this.OverViewitems;
                this.SetBreadCrumb();
            });

      this.wellOverviewService.getWellShortHeader(this.pwi_nmbr_n)
      .pipe(takeUntil(this.destroying$))
              .subscribe((data: WellShortHeaderModel)=> {
                this.wellheader = data;
              });

  }

  ngOnDestroy() {
    this.destroying$.next(true);
    this.destroying$.complete();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  setUrl(menuItem : WellOverviewMenuModel){
    let url = '';

    switch(menuItem.urlType){

      case 0:{
          url = `${menuItem.baseUrl}${menuItem.url}?pwi_nmbr_n=${this.pwi_nmbr_n}&data_type_vc=${menuItem.dataTypeCode}`;
          break;
      }

      case 1:{
          url = `${menuItem.baseUrl}${menuItem.url}?pwi_nmbr_n=${this.pwi_nmbr_n}&data_type_vc=${menuItem.dataTypeCode}&hidden=true`;
          break;
      }

      case 2:{
        url = `${menuItem.baseUrl}${menuItem.url}${this.pwi_nmbr_n}?&dt=${menuItem.dataTypeCode}`;
        break;
      }

      case 3:{
        url = `${menuItem.baseUrl}${menuItem.url}`;
        break;
      }
    }

    if(menuItem.grpCode.toUpperCase() =='SMPL'){
      url = `${menuItem.baseUrl}${menuItem.url}?pwi_nmbr_n=${this.pwi_nmbr_n}`;
    }

    return url;
  }

  SetBreadCrumb(){
    this.breadCrumbItems = [];
    let menuitem = this.items.find(x=>x.id == 0);
    let breadcrumbItem: BreadCrumbItem={
        text: menuitem.text,
        title: menuitem.text,
    }
    this.breadCrumbItems.push(breadcrumbItem);
  }
}
