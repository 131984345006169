import { Injectable, InjectionToken } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../_services';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { MessageErrorService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    callingUrl: string = environment.callingUrl;
    currentUser: User;
    cookieInfo: User;
    source: string;
    environment: string;
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private messageErrorService: MessageErrorService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError((err: HttpErrorResponse) => {
                let errorMessage = '';

                if (err !== null) {
                    if (err.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${err.error.message}`;
                    } else {
                        // server-side error
                        if (err.error !== null) {
                            if ((typeof err.error !== 'object' && JSON.parse(err.error).Message.startsWith("Custom message:"))){
                                errorMessage=JSON.parse(err.error).Message;
                            }
                            else if (typeof err.error === 'object' && err.error.Message !== undefined && err.error.Message.startsWith("Custom message:")){
                                errorMessage=err.error.Message;
                            }
                            else {
                                errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}\n${err.error.Error[0]}`;
                            }
                        }
                    }

                    if (err.status === 401) {
                        const position = window.location.href.indexOf('hidden=true');
                        const position2 = window.location.href.indexOf('/vrsshrd/');
                        if (position !== -1 || position2 !== -1) {
                            const redirectUrl = `${this.callingUrl}account/signin?message=User session expired, Please sign in.`;
                            (window as any).open(redirectUrl, '_blank');
                        } else {
                            // auto logout if 401 response returned from api
                            this.authenticationService.logout();
                            this.router.navigate(['/app-common/landing']);
                        }
                    }

                    if (err.status === 400 && errorMessage.indexOf('Invalid username or password') < 0) {
                        this.router.navigate(['/app-common/message-board', { message: errorMessage }]);
                    }

                    if (err.status === 403) {
                        this.router.navigate(['/app-common/message-board', { message: 'User is not authorized to view this page' }]);
                    }

                    return throwError(errorMessage);
                }
            }));
    }
}
