import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService, AuthenticationService } from '../_services';
import { User, RouteModel } from '../_models';
import { Helper } from '../_helpers';
import { environment } from '../../environments/environment';

@Component({
    selector: 'site-nav',
    templateUrl: './site-nav.component.html',
    styleUrls: ['./site-nav.component.css'],
})
export class SiteNavComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    authuser: User;
    isAuthenticated: boolean;
    url: any;
    routeModel: RouteModel;
    isHidden: boolean;
    _apiURI: string = environment.apiUrl;
    docUrl: string;

    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(map((result) => result.matches));

    constructor(
        private breakpointObserver: BreakpointObserver,
        private messageService: MessageService,
        private authService: AuthenticationService,
        private router: Router,
        private location: Location
    ) {
        this.subscription = this.messageService
            .getMessage()
            .subscribe((message) => {
                if (message && message.msg) {
                    this.authuser = message.msg;
                    localStorage.setItem(
                        'currentUser',
                        JSON.stringify(this.authuser)
                    );
                }
            });

        let hidden = Helper.getQueryString('hidden', this.location.path());
        this.isHidden = hidden === 'true';
        this.docUrl = this._apiURI + 'docs';
    }

    ngOnInit() {
        let user = this.authService.getTokenInfo('COOKIE');

        if (user !== null && user.token) {
            this.authuser = user;
            return;
        }

        if (!Helper.hasData(this.authuser)) {
            this.authuser = JSON.parse(localStorage.getItem('currentUser'));
            if (Helper.hasData(this.authuser)) {
                //this.router.navigate(['/home']);
            } else {
                this.router.navigate(['/app-common/landing']);
            }
        }
    }

    logout() {
        this.authuser = null;
        this.authService.logout();
        this.router.navigate(['/app-common/landing']);
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
}
