import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfigService {
    public configSubject$: Subject<any> = new Subject<any>();
    private appConfig: any;
    private http: HttpClient;

    constructor(private readonly httpHandler: HttpBackend) {
        this.http = new HttpClient(httpHandler);
    }

    async loadAppConfig() {
        return new Promise<boolean>((resolve, reject) => {
            this.http.get('/assets/config/app-settings.json')
                .subscribe(value => {
                    this.appConfig = value;
                    resolve(true);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    public getConfig(key: any) {
        return this.appConfig[key];
    }
}
