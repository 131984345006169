import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RapidWellComponent } from './well-dashboard/rapid-well/rapid-well.component';

const appRoutes: Routes = [
    {
        path: 'app-common',
        loadChildren: () => import('./app-common/app-common.module').then(m => m.AppCommonModule)
    },
    {
        path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
     },
    {
        path: 'chart', loadChildren: () => import('./chart/chart.module').then(m => m.ChartModule) },
    {
        path: 'well-data',
        loadChildren: () => import('./well-data/well-data.module').then(m => m.WellDataModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'ct-scan',
        loadChildren:  () =>import('./ct-scan/ct-scan.module').then(m => m.CtScanModule) },
    { path: 'home', component: HomeComponent },
    { path: 'rapid-well', component: RapidWellComponent},
    { path: '', redirectTo: 'app-common/landing', pathMatch: 'full' },
    // otherwise redirect to home
    { path: '**', redirectTo: 'app-common/landing' },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
