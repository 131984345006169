import { Component, Input, OnInit } from '@angular/core';

import { BreadCrumbItem } from "@progress/kendo-angular-navigation";
import {
  arrowRotateCcwIcon,
  homeIcon,
  SVGIcon,
} from "@progress/kendo-svg-icons";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadCrumbItems: BreadCrumbItem[];
  constructor() { }

  ngOnInit(): void {
  }

  public homeIcon: SVGIcon = homeIcon;
  public rotateIcon: SVGIcon = arrowRotateCcwIcon;
  
  public onItemClick(item: BreadCrumbItem): void {
    const index = this.breadCrumbItems.findIndex((e) => e.text === item.text);
    this.breadCrumbItems = this.breadCrumbItems.slice(0, index + 1);
  }
}
