import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../../_services/config.service';

export abstract class HttpBaseService {
    private postHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    private baseApiUrl = '';

    constructor(private http: HttpClient, configService: ConfigService) {
        if (configService){
            this.baseApiUrl = configService.getConfig('ApiUrl');
        }
    }

    protected get<T>(
        url: string,
        options?: {
            params?: HttpParams | {
                [param: string]: string | string[];
            };
        },
    ): Observable<T> {
        return this.http.get<T>(`${this.baseApiUrl}/${url}`, options);
    }

    protected post<T>(url: string, body: any): Observable<T> {
        return this.http.post<T>(`${this.baseApiUrl}/${url}`, body, { headers: this.postHeaders });
    }

    protected put<T>(url: string, body: any): Observable<T> {
        return this.http.put<T>(`${this.baseApiUrl}/${url}`, body, { headers: this.postHeaders });
    }

    protected delete<T>(url: string, body?: any): Observable<T> {
        return this.http.request<T>('delete', `${this.baseApiUrl}/${url}`, { body });
    }
}
