import { Component, Input, OnInit } from '@angular/core';
import {DrawerItem, DrawerItemExpandedFn, DrawerSelectEvent} from "@progress/kendo-angular-layout";
import { SVGIcon, menuIcon } from "@progress/kendo-svg-icons";
import { DrawerItemWithUrlModel, bannerModel } from '../model/drawer-item';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css']
})

export class DrawerComponent implements OnInit {
  @Input() menuItems: Array<DrawerItem>;
  @Input() wellItems: DrawerItemWithUrlModel[];
  @Input() wellHeader: bannerModel;
  @Input() isProduction: boolean;
  @Input() isOverview: boolean;
  @Input() set overviewUrl(url: string){
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  };
  currentParentIndex: string | number = 0;
  currentChildIndex: string | number = 0;
  @Input() breadCrumbItems: BreadCrumbItem[];
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void { 
  }
  
  public selected = "Overview";
  public menuSvg: SVGIcon = menuIcon;
  public expandedIndices = [];


  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  public onSelect(ev: DrawerSelectEvent): void {
    this.selected = ev.item.text;
    const current = ev.item.id;
    
    if(current == 0 && !environment.production){
      this.isOverview = true;
    }
    else{
      this.isOverview = false;
    }

    //set url
    let wellitem = this.wellItems.find(x=>x.id == ev.item.id);

    if(wellitem !=undefined){
      this.selected = this.wellItems.find(x=>x.id == ev.item.id).url;

      if(this.selected.includes('?&dt=')){
        window.open(this.selected, "_blank");
      }

      else{

        if(wellitem !=undefined){
              //create breadcrumb
          this.SetBreadCrumb(ev);
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.selected);
        }

      }
    }
    let menuitem = this.menuItems.find(x=>x.id == ev.item.id);
    //if item is a child
    if(menuitem.parentId != undefined && this.expandedIndices.includes(menuitem.parentId)){

      if(this.currentChildIndex != 0){
        this.menuItems.filter(x => x.id == this.currentChildIndex).forEach(x => x.cssClass = undefined);
        this.currentChildIndex = 0;
      }

      this.menuItems.filter(x => x.id == menuitem.parentId).forEach(x => x.cssClass = "parentclass");
      this.menuItems.filter(x => x.id == menuitem.id).forEach(x => x.cssClass = "childclass");
      this.currentParentIndex = menuitem.parentId;
      this.currentChildIndex = menuitem.id;
    }
    //item is a parent
    if(menuitem.parentId == undefined && !this.expandedIndices.includes(menuitem.id)){

      if(this.currentParentIndex == 0){
        this.menuItems.filter(x => x.id == this.currentParentIndex).forEach(x => x.cssClass = undefined);
      }

      if(this.currentParentIndex != 0){
        this.menuItems.filter(x => x.id == this.currentParentIndex).forEach(x => x.cssClass = undefined);
      }

      if(this.currentChildIndex != 0){
        this.menuItems.filter(x => x.id == this.currentChildIndex).forEach(x => x.cssClass = undefined);
        this.currentChildIndex = 0;
      }

      this.currentParentIndex = menuitem.id;
      this.menuItems.filter(x=>x.id == current).forEach(x=>x.cssClass="parentclass");
      this.menuItems.filter(x => x.parentId == menuitem.id).forEach(x => x.cssClass = undefined);
      this.expandedIndices = [];
      this.menuItems.filter(x => x.id == current || x.parentId == current).forEach(x => this.expandedIndices.push(x.id));
    }

    else{

      if (this.expandedIndices.indexOf(current) >= 0) {
        this.expandedIndices = this.expandedIndices.filter(
          (id) => id !== current
        );
      } 

      else {
        this.expandedIndices.push(current);
      }

    }
    
  }

  SetBreadCrumb(ev: DrawerSelectEvent){
    this.breadCrumbItems = [];
    let menuitem = this.menuItems.find(x=>x.id == ev.item.id);
    let idWithchild = this.menuItems.filter(x=>x.parentId == menuitem.id);

    if(idWithchild.length == 0){
        //set parent breadcrumb
        if(menuitem.parentId!="" && menuitem.parentId !=undefined){
          let parentitem = this.menuItems.find(x=>x.id == ev.item.parentId);
          let breadcrumbItem: BreadCrumbItem={
            text: parentitem.text,
            title: parentitem.text,
            disabled: true
          }
          this.breadCrumbItems.push(breadcrumbItem);
        }
        //set child breadcrumb
        let breadcrumbItem: BreadCrumbItem={
            text: menuitem.text,
            title: menuitem.text,
            disabled: true
        }
        this.breadCrumbItems.push(breadcrumbItem);
    }
    
  }
}
